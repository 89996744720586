import React from 'react';
import css from './NavbarRadio.module.scss';

const NavbarRadio = ({text, customClass}) => {
  return (
    <span className={`${css.navbar_radio} ${customClass}`}>
    <p>{text ? text : ''}</p>
  </span>
  );
};

export default NavbarRadio;
