import React from "react";
import css from './LoginButton.module.scss';
import LinksProvider from '../../../services/http/LinksProvider';
import PageLink from "../../../components/common/page-link/PageLink";

const LoginButton = () => {
  return (<PageLink href={LinksProvider.get(LinksProvider.ROUTES.AUTH.SIGN_IN)}>
    <a>
      <span>
        <button className={css.auth__btn}>
          <img src="../../../static/images/pictos/icon-user.svg"/>
          <span className={css.auth__content}>Connexion</span>
        </button>
      </span>
    </a>
  </PageLink>);
};

export default LoginButton;
