import React, { useEffect, useState } from 'react';
import PageLink from "../../../components/common/page-link/PageLink";
import { withTranslation } from "../../../i18n";
import LinksProvider from '../../../services/http/LinksProvider';
import ManageCoookiesModal from "../../cookies/ManageCookieModal/ManageCookieModal";
import css from './FooterSitemap.module.scss';

const namespacesRequired = ['footer'];


const FooterSitemap = ({ t }) => {

  const [showManageCookieModal, setShowManageCookieModal] = useState(false);
  // const [isMarketingCookiesActive, setIsMarketingCookiesActive] = useState(null);

  useEffect(() => {
    // UNCOMMENT THIS CODE FOR HIDING SOCIAL NETWORK ICON
    // setIsMarketingCookiesActive(CookiesService.isMarketingCookieActive());
    // if (typeof globalThis !== 'undefined') {
    //   globalThis.window.addEventListener(CookiesService.MARKETING__COOKIE_TOOGLED_EVENT_NAME, () => {
    //     setIsMarketingCookiesActive(CookiesService.isMarketingCookieActive());
    //   }, false);
    // }
  }, []);

  return (
    <div className={css.footer}>
      {(showManageCookieModal) && (
        <ManageCoookiesModal visible={showManageCookieModal}
        onHide={() => {setShowManageCookieModal(false);}}
        onSubmit={() => {setShowManageCookieModal(false);}}
        />
      )}

        <div className={css.socialShare}>
          <a href={'https://www.instagram.com/galerieslafayette_lacagnotte/'} target={'_blank'}>
            <img src={'/static/images/footer/icon-instagram.svg'} alt={'instagram'} />
          </a>
          <a href={'https://www.pinterest.fr/galerieslafayette_lacagnotte/'} target={'_blank'}>
            <img src={'/static/images/pictos/icon-pinterest.png'} alt={'pinterest'} style={{ backgroundColor: '#fff' }} />
          </a>
        </div>

      <ul className={css.footer_sitemap}>
        <li>
          <PageLink href={LinksProvider.get(LinksProvider.ROUTES.FAQ)}>
            <a>
              <span>{t('footer:link.faq')}</span>
            </a>
          </PageLink>
        </li>

        <li>
          <PageLink href={LinksProvider.ROUTES.TERMS}>
            <a>
              <span>{t('footer:link.cgu')}</span>
            </a>
          </PageLink>
        </li>

        <li>
          <a href={'mailto:contactcagnotte@galerieslafayette.com'}>
            <span>{t('footer:link.contact')}</span>
          </a>
        </li>

        <li>
          <a href="/static/pdf/pvp_gl_cagnotte_2022.pdf" target="_blank">
            <span>{t('footer:link.rgpd')}</span>
          </a>
        </li>
        <li>
          <a href={null} onClick={() => setShowManageCookieModal(true)}>
            <span>{t('footer:link.cookies')}</span>
          </a>
        </li>
        <li>
          <a href="tel:+33805530053">
            <img src={"/static/images/footer/numero.svg"} />
          </a>
        </li>
      </ul>

    </div>
  );
};

export default withTranslation(namespacesRequired)(FooterSitemap);
