import React from 'react';
import css from './_navlink.module.scss';

import PropTypes from 'prop-types';
import PageLink from "../../../components/common/page-link/PageLink";


const Navlink = (props) => {
  return props.url ?

    <PageLink href={props.url}>

      <a className={`${css.navlink} ${props.active ? css.active : ""} ${props.customClass ? props.customClass : ""}`}>
        <span>{props.name}</span>
      </a>
    </PageLink> : null;

};

Navlink.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string
};

Navlink.defaultProps = {
  url: "/",
  name: "navLink"
};

export default Navlink;
