/**
 * StringService
 * @module Services/utils/StringService
 * @description Offers utility functions to handle strings
 */


const self = {};


/**
 * Gets the initials of a person
 * @author Hassen Charef
 * @alias getInitials
 * @memberof module:Services/utils/StringService
 * @param   {string} firstName The first name of the person
 * @param   {string} lastName The first name of the person
 * @returns {string} The initials of the person
 */
self.getInitials = (firstName, lastName) => {
  if (!firstName || !lastName) {
    return "Anon";
  }
  return firstName.substr(0, 1).toUpperCase() + lastName.substr(0, 1).toUpperCase();
};

/**
 * Convert string to kebab case
 * @author Seif Khiari
 * @alias toKebabCase
 * @memberof module:Services/utils/StringService
 * @param   {string} entry the entry string to converte
 * @returns {string} the result converted to kebab case
 */
self.toKebabCase = (entry) => {
  if (!entry) {
    return "";
  }
  return entry
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');
};


export default self;
