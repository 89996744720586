import React, {useState} from 'react';
import css from './FooterNewsletter.module.scss';
import {withTranslation} from "../../../i18n";
import UserService from "../../../services/domain/UserService";
import {useToasts} from 'react-toast-notifications';


const namespacesRequired = ['footer'];

const FooterNewsletter = ({t}) => {

  const [loading, setLoading] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState(false);
  const {addToast} = useToasts();

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const valueToUpdate = {
      email: newsletterEmail,
      opt_in_1: true,
      opt_in_2: true
    };
    return UserService.updateOptins(valueToUpdate).then((user) => {
      addToast(t('footer:newsletter-confirm-message'), {
        appearance: 'success', autoDismiss: true, pauseOnHover: true
      });
    })
        .catch(error => {
          if (error.data && error.data.errors && error.data.errors[0] && error.data.errors[0].code === "E_INVALID_EMAIL") {
            addToast(t('footer:newsletter-error-message'), {
              appearance: 'error', autoDismiss: true, pauseOnHover: true
            });
          } else {
            addToast(t('footer:newsletter-error-message-default'), {
              appearance: 'error', autoDismiss: true, pauseOnHover: true
            });
          }

        })
        .finally(() => setLoading(false));
  };

  return (
      <div className={css.newsletter}>
        <div className={css.newsletter_content}>
          <h2>{t('footer:subscribe-title')}</h2>
          <p dangerouslySetInnerHTML={{__html: t('footer:subscribe-desc')}}/>
        </div>


        <form onSubmit={onSubmit} className={css.newsletter_form}>
          <input
              className={css.input}
              name="newsletter"
              id={"newsletter"}
              onChange={(e) => setNewsletterEmail(e.target.value)}
              disabled={loading}
              placeholder={t('footer:newsletter-placeholder')}
          />
          <button type={'submit'} disabled={loading} className={css.button}><span>{t('footer:validate')}</span>
          </button>
        </form>

      </div>
  );
};

export default withTranslation(namespacesRequired)(FooterNewsletter);
