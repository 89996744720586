import React, {useEffect, useRef} from 'react';
import DomService from '../../services/utils/DomService';
import css from './Footer.module.scss';
import FooterNewsletter from './footerNewsletter/FooterNewsletter';
import FooterSitemap from "./footerSitemap/FooterSitemap";
import { useRouter } from 'next/router';

export default function Footer() {
  const footerRef =  useRef();
  const router = useRouter();
  
  const handlePageScroll = (e) => {
    if (!footerRef.current) {
      return;
    }

    const footerHeight = footerRef.current.offsetHeight;
    const breakPoint = DomService.documentHeight() - window.innerHeight - footerHeight;
    const scrollY = window.scrollY;

    if (scrollY ===  0) {
      footerRef.current.style.opacity = 'unset';
      footerRef.current.style.filter = 'unset';
      footerRef.current.style.bottom = 'unset';
    } else if (scrollY > breakPoint) {
      const diff =  scrollY - breakPoint;
      const translate = (footerHeight - diff) * 0.3;
      const ratio = diff / footerHeight;
      footerRef.current.style.opacity = ratio;
      footerRef.current.style.filter = `alpha(opacity=${ratio * 100})`;
      footerRef.current.style.bottom = `-${translate}px`;
    } 
  };

  useEffect(() => {
    window.addEventListener('scroll', handlePageScroll);

    return () => window.removeEventListener('scroll', handlePageScroll);
  }, []);


  useEffect(() => {
    const scrollY = window.scrollY;

    if (scrollY ===  0) {
      footerRef.current.style.opacity = 'unset';
      footerRef.current.style.filter = 'unset';
      footerRef.current.style.bottom = 'unset';
    }
  }, [router])


  return (<footer ref={footerRef} id="footer" className={css.footer}>
    <FooterNewsletter/>
    <FooterSitemap/>
  </footer>);
}
