import React from 'react';
import {withTranslation} from '../../i18n';
import css from './MobileNavbar.module.scss';
import Navlink from "../navbar/navlink/Navlink";
import LinksProvider from "../../services/http/LinksProvider";
import PageLink from "../../components/common/page-link/PageLink";

const namespacesRequired = ['common'];

const NavbarMobile = ({t, isMenuOpen, triggerSidebar}) => {
  return (
    <div className={`${css.sidebar} ${isMenuOpen === true ? css.open : ""} ${isMenuOpen === false ? css.close : ""}`}>
      <section className={css.nav__group}>
        <Navlink url="/concept"
                 name={t('common:navbar.ccm')}/>
        <Navlink url={LinksProvider.get(LinksProvider.ROUTES.SPENDING_INFORMATION)}
                 name={t('common:navbar.fees')}/>
        <Navlink url={LinksProvider.get(LinksProvider.ROUTES.PRODUCT.SEARCH_NO_PARAMS)}
                 name={t('common:navbar.gifts')}/>
        <PageLink href={LinksProvider.get(LinksProvider.ROUTES.POT.CREATE)}>
          <a className={css.btn__primary}>
            {t('common:create-pot')}
          </a>
        </PageLink>
      </section>

    </div>
  );
};

export default withTranslation(namespacesRequired)(NavbarMobile);
