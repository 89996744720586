import React from "react";
import {Link} from "../../../routes";
import queryString from "query-string";

const PageLink = ({href, query, children}) => {
  let q = query ? `?${queryString.stringify(query)}` : '';

  return (
      <Link route={href + q}>
        {children}
      </Link>);
};

export default PageLink;