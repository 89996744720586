/**
 * DomService
 * @module Services/utils/DomService
 * @description Offers utility functions to interract with dom
 */

import Scroll from "react-scroll";

const self = {};

/**
 * Gets the offset of an element
 * @author Sameh Bellez
 * @alias offset
 * @memberof module:Services/utils/DomService
 * @param   {object} element The DOM element
 * @returns {number}  The element's offset
 */
self.offset = (element) => {
  let top = 0;
  if (element.offsetParent) {
    do {
      top += element.offsetTop;
    } while (element = element.offsetParent);
    return top;
  }
};

/**
 * Scrolls to a specific offset
 * @author Sameh Bellez
 * @alias scrollTo
 * @memberof module:Services/utils/DomService
 * @param   {number} offset the offset to scroll to
 * @param   {number} additionalOffset additional offset value to calculate
 * @param   {number} scrollDuration duration of scroll in ms
 * @returns {object}  The element's offset
 */
self.scrollTo = (offset, additionalOffset, scrollDuration) => {
  const scroll = Scroll.animateScroll;
  scroll.scrollTo(offset - additionalOffset, {duration: scrollDuration || 200});
};

/**
 * Scrolls to a ref
 * @alias scrollToRef
 * @memberof module:Services/utils/DomService
 * @param   {object} ref ref of a DOM element, obtained through methods such as React.createRef() or React.useRef()
 * @param   {number} additionalOffset additional offset value to calculate
 */
self.scrollToRef = (ref, additionalOffset = 0) => {
  self.scrollTo(ref.current.offsetTop, additionalOffset);
};

/**
 * Loads a script and attaches it to the  DOM tree
 * @author Sameh Bellez
 * @alias loadScript
 * @memberof module:Services/utils/DomService
 * @param   {string} scriptId The script tag id
 * @param   {string} scriptUr The script url
 * @param   {Function} The callback to execute after the script loads
 */
self.loadScript = (scriptId, scriptUrl, callback) => {

  if (document.querySelector(`#${scriptId}`)) {
    return;
  }

  const script = document.createElement('script');
  script.src = scriptUrl;
  script.id = scriptId;
  script.async = true;
  script.onload = () => setTimeout(callback, 500);
  document.body.appendChild(script);
};

/**
 * Gets the document height
 * @author Sameh Bellez
 * @alias documentHeight
 * @memberof module:Services/utils/DomService
 * @return   {number} The document height
 */
self.documentHeight = () => {
  let body = window.document.body, html = window.document.documentElement;

  return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
};

export default self;
