import React, {useEffect, useState} from 'react';
import Navlink from './navlink/Navlink.js';
import Navbrand from './navbrand/Navbrand';
import LoginButton from './login-button/LoginButton';
import ProfileButton from './profile-button/ProfileButton';

import css from './_navbar.module.scss';
import LinksProvider from '../../services/http/LinksProvider';
import {withTranslation} from '../../i18n';

import NavbarRadio from '../navbar-radio/NavbarRadio';
import UserService from '../../services/domain/UserService';
import PageLink from "../../components/common/page-link/PageLink";

const namespacesRequired = ['common'];

const Navbar = ({principal, potPage, dashboard, t, conceptPage, spendPage, giftPage, triggerSidebar, isMenuOpen}) => {

  const [scrollZero, setScrollZero] = useState(true);
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [windowWidth, setWindowWidth] = useState(null);
  const [user, setUser] = useState(principal);

  let handlePageScroll = () => {
    if (window.scrollY === 0) {
      setScrollZero(true);
    }

    if (window.scrollY !== 0) {
      setScrollZero(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handlePageScroll);
    handlePageScroll();
    setWindowWidth(window.innerWidth);
    setComponentLoaded(true);
    UserService.getAccountProfile().then(setUser);
  }, []);

  return (<React.Fragment>
    <nav
      className={`${css.nav}
                  // ${scrollZero ? '' : css.scrolled}
                  ${potPage ? css.nav__pot : ''}
                  ${dashboard ? css.nav__dashboard : ''}`}
      role="navigation"
    >

      <img src={isMenuOpen ? "/static/images/pictos/burger-close-1.svg" : "/static/images/pictos/icon-burger.svg"}
           onClick={triggerSidebar}
           className={css.nav__bars}
      />

      <Navbrand imgSrc="/static/images/logos/logo.svg"
                imgDesc={t('common:navbar.logo-alt')}
                styleOverwrite={css.overwrite}
      />

      <section
        className={`${css.nav__menu}`}
      >
        <section className={css.nav__group}>
          <Navlink url={LinksProvider.get(LinksProvider.ROUTES.CONCEPT)}
                   name={t('common:navbar.ccm')}
                   active={conceptPage}
          />
          <Navlink url={LinksProvider.get(LinksProvider.ROUTES.SPENDING_INFORMATION)}
                   name={t('common:navbar.fees')}
                   active={spendPage}
          />
          <Navlink url={LinksProvider.get(LinksProvider.ROUTES.PRODUCT.SEARCH_NO_PARAMS)}
                   name={t('common:navbar.gifts')}
                   active={giftPage}
          />
        </section>

        <PageLink href={LinksProvider.get(LinksProvider.ROUTES.POT.CREATE)}>
          <a className={css.btn__primary}>{t('common:navbar.create')}</a>
        </PageLink>
      </section>

      <section className={css.nav__group}>
        {!principal && (<LoginButton/>)}
        {principal && (<React.Fragment>
          <ProfileButton principal={principal}/>
          {user && user.n_user_notifications && (user.n_user_notifications > 0) && (
            <NavbarRadio text={user.n_user_notifications}/>)}
        </React.Fragment>)}
      </section>
    </nav>
  </React.Fragment>);

};

export default withTranslation(namespacesRequired)(Navbar);
