import React from 'react';
import StringService from "../../services/utils/StringService";
import css from "./UserAvatar.module.scss";


export const UserAvatar = ({customClass, profilePicture, lastName, firstName, profile}) => {

  const renderSocialTag = () => {
    const hasFbAccount = profile.has_fb_account;
    const hasGoogleAccount = profile.has_google_account;
    const hasFbAndGoogleAccount = hasFbAccount && hasGoogleAccount;

    if (hasFbAndGoogleAccount) {
      return null;
    } else if (hasFbAccount) {
      return <span className={css.social_tag}>
                <img src={'/static/images/pictos/icon-social-facebook.svg'}/>
              </span>;
    } else if (hasGoogleAccount) {
      return <span className={css.social_tag}>
                <img src={'/static/images/pictos/icon-google.svg'}/>
              </span>;
    }
  };


  return (

    <span className={`${css.user_avatar} ${customClass || ''}`}>
      <React.Fragment>
        {profilePicture && (
          <div className={css.profile_pic}
               style={{backgroundImage: `url('${profilePicture}')`}}
          />
        )}
        {!profilePicture && (StringService.getInitials(firstName, lastName))}
        {profile && renderSocialTag()}
      </React.Fragment>

    </span>);
};
