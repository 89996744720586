import React, {useState} from "react";
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import css from './_layout.module.scss';
import {withTranslation} from "../../i18n";
import MobileNavbar from "../MobileNavbar/MobileNavbar";

const namespacesRequired = ['common'];

const LayoutLanding = (props) => {

  const [isMenuOpen, setIsMenuOpen] = useState(null);

  let handleMenuToggle = () => {
    if (isMenuOpen === null) {
      return setIsMenuOpen(true);
    }
    return setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`App theme__${props.theme || 'classic'}`}>
      <Navbar principal={props.principal}
              potPage={props.potPage}
              conceptPage={props.conceptPage}
              spendPage={props.spendPage}
              giftPage={props.giftPage}
              dashboard={props.dashboard}
              triggerSidebar={() => handleMenuToggle()}
              isMenuOpen={isMenuOpen}
              user={props.user}/>

      <MobileNavbar isMenuOpen={isMenuOpen} triggerSidebar={() => handleMenuToggle()}/>

      <main className={`${css.main__content} ${props.pageClass || ''} `}>
        {props.children}
      </main>
      <Footer/>
    </div>);
};

export default withTranslation(namespacesRequired)(LayoutLanding);


