/**
 * UserService
 * @module Services/domain/UserService
 * @description Offers a set of methods to help with user information
 */

import HttpService from "../http/HttpService";
import LinksProvider from '../http/LinksProvider';
import AuthService from "./AuthService";

const self = {};

/**
 * Gets user notifications
 * @author Paris Romain
 * @alias get
 * @param limit {number} The notification count needed
 * @param offset {number} The notification start index
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the resulting notifications
 */
self.getNotifications = (limit, offset) => {
  const url = `${LinksProvider.get(LinksProvider.API.USERS.NOTIFICATIONS)}?limit=${limit || 4}&offset=${offset || 0}`;
  return HttpService().get(url)
    .then(result => result);
};

/**
 * Update notification seen status
 * @author Hassen Charef
 * @alias PUT
 * @param reference {string} The notification reference
 * @param values {object} The values to be updated
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the resulting notifications
 */
self.updateNotificationSeenStatus = (reference, values) => {
  const url = LinksProvider.get(LinksProvider.API.USERS.NOTIFICATION, {reference: reference});
  return HttpService().put(url, values)
    .then(result => result);
};

/**
 * Gets user account status
 * @author Paris Romain
 * @alias get
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the resulting account status
 */
self.getAccountProfile = (ctx) => {
  if (AuthService.getPrincipal(ctx)) {
    return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_PROFILE))
      .then(result => result.user);
  } else {
    return new Promise((resolve) => {
      resolve();
    });
  }

};

/**
 * Gets user account status
 * @author Paris Romain
 * @alias get
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the resulting account status
 */
self.getCurrentUser = () => {
  return HttpService().get(LinksProvider.get(LinksProvider.API.KYC.ME))
      .then((result) => {
        return result.user;
      })
}

/**
 * Gets user account status
 * @author Paris Romain
 * @alias get
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the resulting account status
 */
self.update = (values) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.USERS.USERS), values)
    .then(result => result.user);
};

/**
 * Gets user account status
 * @author Paris Romain
 * @alias get
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the resulting account status
 */
self.updateKyc = (values) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.KYC.USERS), values)
    .then(result => result.user);
};

/**
 * Create user rib
 * @author Paris Romain
 * @alias post
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing an user iban
 */
self.createRib = (values) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.USER_CREATE_RIB), values)
    .then(result => result.ribs);
};

/**
 * Gets user rib
 * @author Paris Romain
 * @alias get
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the user rib
 */
self.getUserRib = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_GET_RIB))
    .then(result => result.ribs);
};

/**
 * Delete a user rib
 * @author Paris Romain
 * @alias delete
 * @param reference {string}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the deleted user rib
 */
self.deleteUserRib = (reference) => {
  return HttpService().delete(LinksProvider.get(LinksProvider.API.USERS.USER_DELETE_RIB, {reference: reference}))
    .then(result => result.ribs);
};

/**
 * update user optins
 * @author Paris Romain
 * @alias updateOptins
 * @param reference {string}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updatedUser
 */
self.updateOptins = (values) => {
  return HttpService().put(LinksProvider.get(LinksProvider.API.USERS.USER_OPTINS), values)
    .then(result => result);
};

/**
 * update user email
 * @author Paris Romain
 * @alias updateEmail
 * @param reference {string}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updated email
 */
self.updateEmail = (values) => {
  return HttpService().post(LinksProvider.get(LinksProvider.API.USERS.UPDATE_EMAIL), values)
    .then(result => result);
};

/**
 * Confirm email update
 * @author Romain Paris
 * @alias ConfirmEmailUpdate
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the updated email
 */
self.confirmEmailUpdate = function (ctx, reference, request) {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.CONFIRM_EMAIL_UPDATE, {reference: reference}), request)
    .then((response) => {
      return response;
    });
};


/**
 * Activates an account
 * @author Romain Paris
 * @alias resendActivateAccount
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the request result
 */
self.resendActivateAccount = function (request) {
  return HttpService().get(LinksProvider.get(LinksProvider.API.AUTH.RESEND_ACTIVATE_ACCOUNT), request)
    .then((response) => {
      return response;
    });
};

/**
 * Get user spending
 * @author Romain Paris
 * @alias getUserSpending
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the request result
 */
self.getUserSpending = function (ctx) {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_SPENDING))
    .then(result => result.pots);
};


/**
 * Gets user pools
 * @author Paris Romain
 * @alias get
 * @param ctx {object}
 * @memberof module:Services/domain/UserService
 * @returns {promise}  A promise containing the user pools
 */
self.getUserPool = (ctx) => {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_POT))
    .then(result => result.pots);
};

/**
 * Get user spending
 * @alias checkUserHasExpenditures
 * @memberof module:Services/domain/UserService
 * @returns {promise} A promise containing the request result
 */
self.checkUserHasExpenditures = function (ctx) {
  if (AuthService.getPrincipal(ctx)) {
  return HttpService(ctx).get(LinksProvider.get(LinksProvider.API.USERS.USER_CHECK_EXPENDITURES))
    .then(result => result.hasExpenditures);
  } else {
    return new Promise((resolve) => {
      resolve();
    });
  }
};


export default self;
